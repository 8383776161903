<template>
  <div class="box has-max-width">
    <h3 class="title is-5 push-bottom-xxs">{{ $t('profile.settings.notifications.title') }}</h3>
    <p>{{ $t('profile.settings.notifications.helpText') }}</p>

    <form v-if="isPopulated" @submit.prevent="update">
      <FormField>
        <Checkbox
          v-model="notifications.weHunt"
          :disabled="isLoading"
          :label="$t('profile.settings.notifications.weHunt')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="notifications.jaktJournalen"
          :disabled="isLoading"
          :label="$t('profile.settings.notifications.jaktJournalen')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="notifications.sjf"
          :disabled="isLoading"
          :label="$t('profile.settings.notifications.sjf')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="notifications.svenskJakt"
          :disabled="isLoading"
          :label="$t('profile.settings.notifications.svenskJakt')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="notifications.njff"
          :disabled="isLoading"
          :label="$t('profile.settings.notifications.njff')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="notifications.dj"
          :disabled="isLoading"
          :label="$t('profile.settings.notifications.dj')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="notifications.theGuardian"
          :disabled="isLoading"
          :label="$t('profile.settings.notifications.theGuardian')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="notifications.theField"
          :disabled="isLoading"
          :label="$t('profile.settings.notifications.theField')">
        </Checkbox>
      </FormField>

      <FormField>
        <Checkbox
          v-model="notifications.shootingUK"
          :disabled="isLoading"
          :label="$t('profile.settings.notifications.shootingUK')">
        </Checkbox>
      </FormField>

      <div class="field is-grouped push-top-xl">
        <div class="flex align-center">
          <div class="control">
            <button :class="{ 'is-loading': isLoading }" class="button is-primary">
              {{ $t('general.save') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isLoading: false,
      isPopulated: false
    }
  },

  static () {
    return {
      notifications: {}
    }
  },

  computed: {
    profile () {
      return this.$store.getters['profile/data']
    }
  },

  mounted () {
    this.populate()
  },

  methods: {
    populate () {
      if (this.profile) {
        Object.assign(this.notifications, this.profile.settings.notifications)

        this.isPopulated = true
      }
    },

    async update () {
      this.isLoading = true

      try {
        await this.$store.dispatch('profile/update', {
          userId: this.profile.userId,
          settings: {
            notifications: this.notifications
          }
        })
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.errorOccured'))
        console.error(error)
      }

      this.isLoading = false
    }
  }
}
</script>
